import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { QueryClient } from '@tanstack/react-query';
import { Button } from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <div
    className="w-full h-full flex flex-col justify-center items-center bg-white gap-5"
    style={{ minHeight: '100vh' }}
  >
    <p className="text-black text-bold text-l">Сайт переехал на новый домен</p>
    <Button
      onClick={() => window.location.replace('https://stroyset-web.vercel.app')}
    >
      Перейти на новый адрес
    </Button>
  </div>,
);

// root.render(
//   <React.StrictMode>
//     <ErrorProvider>
//       <QueryClientProvider client={client}>
//         <ConfigProvider locale={locale}>
//           <ApiContextProvider>
//             <FirebaseProvider>
//               <OptPricesProvider>
//                 <SettingsProvider>
//                   <AnalyticsTableFiltersProvider>
//                     <Theme dataTheme="light">
//                       <div
//                         className={'flex flex-col bg-gray-100 h-full'}
//                         style={{ height: '100vh' }}
//                       >
//                         <BrowserRouter>
//                           <Healthcheck />
//                           <Navigator />
//                           <AppPreloader />
//                           <YandexMetrika />
//                           <Toaster position="top-right" reverseOrder={false} />
//                         </BrowserRouter>
//                       </div>
//                     </Theme>
//                   </AnalyticsTableFiltersProvider>
//                 </SettingsProvider>
//               </OptPricesProvider>
//             </FirebaseProvider>
//           </ApiContextProvider>
//         </ConfigProvider>
//       </QueryClientProvider>
//     </ErrorProvider>
//   </React.StrictMode>,
// );
